@import '../../../scss/theme-bootstrap';

body.non-intrusive-popup-enabled {
  #cboxContent {
    .loyalty_popover {
      .loyalty_popover__content {
        .loyalty_popover__input {
          .loyalty_popover__submit-container {
            .form-submit {
              margin: 5px 0 0;
              padding: 0;
              height: 64px;
              width: 308px;
              @media #{$cr19-medium-up} {
                margin-top: 0;
              }
            }
            .form-text {
              display: block;
              height: 64px;
              padding: 25px;
              width: 308px;
              @media #{$cr19-medium-up} {
                padding: 0 13px;
              }
            }
          }
          .loyalty_popover__password {
            margin-top: 0;
            .form-password {
              font-size: 17px;
              height: 64px;
              margin-bottom: 0;
              width: 308px;
              @media #{$cr19-medium-up} {
                margin-bottom: 15px;
                margin-top: 10px;
                padding: 0 13px;
              }
            }
            .form-submit {
              height: 64px;
              margin-#{$ldirection}: 0;
              width: 308px;
            }
          }
          .hidden {
            .form-submit {
              display: none;
            }
          }
        }
        .loyalty_popover__header {
          font-size: 24px;
          line-height: 1;
          margin: 0 0 10px;
          @media #{$cr19-medium-up} {
            font-size: 22px;
          }
        }
        .loyalty_popover__terms {
          font-size: 13px;
          margin: 10px 0;
          position: inherit;
        }
        .loyalty_popover__margin {
          margin-top: 13px;
          @media #{$cr19-medium-up} {
            margin-top: 0;
          }
          .loyalty_popover__promo-text,
          .loyalty_popover__promo-header {
            font-size: 15px;
            margin: 0;
            @media #{$cr19-medium-up} {
              font-size: 13px;
              line-height: 1.2;
            }
          }
          .loyalty_popover__terms {
            @media #{$cr19-medium-up} {
              margin: 0;
            }
          }
        }
        .loyalty_popover__welcome-option {
          .loyalty_popover__status {
            font-size: 15px;
            margin: 0;
            @media #{$cr19-medium-up} {
              font-size: 13px;
            }
          }
          .loyalty_popover__buttons {
            display: block;
            margin-top: 10px;
            width: 308px;
            @media #{$cr19-medium-up} {
              margin-top: 0;
            }
            a.button.elc-button {
              font-size: 17px;
              height: 64px;
              margin-#{$rdirection}: 8px;
              min-width: 0;
              @media #{$cr19-medium-up} {
                margin-top: 15px;
                width: 308px;
              }
              &:hover {
                background-color: $color-white;
                color: $color-black;
              }
            }
          }
        }
        .loyalty_popover__logo img {
          margin-bottom: 10px;
          width: 120px;
        }
        .background-img {
          width: 50%;
        }
      }
    }
  }
  .password-field__info {
    margin-top: 0;
    @media #{$cr19-medium-up} {
      margin-top: 5px;
      width: 85%;
    }
    .password-field__rules {
      li {
        padding: 0;
        @media #{$cr19-medium-up} {
          padding: 3px 0;
        }
        &.pass {
          color: $color-green-light;
        }
      }
    }
  }
  #colorbox {
    height: 499px !important;
    width: 371px !important;
    @media #{$cr19-medium-up} {
      height: 351px !important;
    }
    #cboxWrapper {
      height: 450px !important;
      width: 359px !important;
      @media #{$cr19-medium-up} {
        height: 348px !important;
      }
      #cboxContent {
        height: 430px !important;
        width: 308px !important;
        @media #{$cr19-medium-up} {
          height: 336px !important;
          width: 320px !important;
        }
        #cboxLoadedContent {
          height: 314px;
          width: 310px !important;
        }
      }
    }
  }
  .loyalty_popover__welcome-option {
    .loyalty_popover__promo-header {
      font-size: 20px;
    }
    .loyalty_popover__promo-text {
      font-size: 20px;
      @media #{$cr19-medium-up} {
        font-size: 15px;
      }
      margin-top: 0;
    }
  }
  #cboxOverlay {
    background: none;
  }
  &.shift-optins-above:not(.loyalty_offer_valid_unused):not(.loyalty_offer_invalid_unused) {
    #colorbox {
      height: 470px !important;
      @media #{$cr19-medium-up} {
        position: absolute !important;
      }
      #cboxWrapper {
        height: 468px !important;
        #cboxContent {
          height: 420px !important;
          width: 310px !important;
          #cboxLoadedContent {
            height: auto !important;
            margin-bottom: 0;
          }
        }
      }
      .loyalty_popover {
        &__header {
          font-size: 20px;
          @media #{$cr19-medium-up} {
            font-size: 24px;
          }
        }
        &__submit-container {
          padding-bottom: 20px;
        }
        &__newsletter-signup {
          padding-bottom: 20px;
          padding-top: 20px;
        }
        &__terms {
          width: auto;
        }
      }
    }
    &.newsletter-with-loyalty:not(.loyalty_offer_valid_unused):not(.loyalty_offer_invalid_unused) {
      #colorbox {
        &.loyalty-offer-overlay {
          box-shadow: 0 2px 10px $pick_x_title-color;
          width: 371px !important;
          @media #{$cr19-medium-up} {
            height: 410px + $newsletter-height-enrollment-popup !important;
            width: 450px !important;
          }
          @media #{$cr19-xxxlarge-up} {
            width: 502px !important;
          }
          @media #{$xlargexxl-up} {
            height: 416px + $newsletter-height-enrollment-popup !important;
            width: 508px !important;
          }
          #cboxWrapper {
            @media #{$cr19-medium-up} {
              height: 408px + $newsletter-height-enrollment-popup !important;
              width: 448px !important;
            }
            @media #{$cr19-xxxlarge-up} {
              width: 504px !important;
            }
            @media #{$xlargexxl-up} {
              height: 410px + $newsletter-height-enrollment-popup !important;
              width: 504px !important;
            }
            #cboxContent {
              width: 330px !important;
              @media #{$cr19-medium-up} {
                padding: 20px;
                width: 410px !important;
                height: 368px + $newsletter-height-enrollment-popup !important;
              }
              @media #{$xlargexxl-up} {
                width: 470px !important;
              }
              @media #{$cr19-xxxlarge-up} {
                width: 474px !important;
              }
              .loyalty_popover {
                &__form-container {
                  .form-text {
                    position: relative;
                    width: 100%;
                    z-index: 9;
                  }
                }
                &__newsletter-signup {
                  padding: 10px 0;
                }
              }
              #cboxLoadedContent {
                height: auto !important;
                margin-bottom: 0;
                width: 100% !important;
                @media #{$cr19-medium-up} {
                  width: auto !important;
                }
              }
            }
          }
        }
      }
      .loyalty_popover {
        &__form-container {
          height: 230px;
          overflow-y: auto;
          padding-#{$rdirection}: 15px;
          @media #{$cr19-medium-up} {
            height: auto;
            overflow-y: unset;
            padding-#{$rdirection}: 0;
          }
        }
        .form-submit {
          position: fixed;
          bottom: 38px;
          width: 89%;
          @media #{$cr19-medium-up} {
            position: unset;
            width: 100% !important;
          }
        }
        &__terms {
          width: 100% !important;
        }
        &__email-empty-error {
          position: fixed;
          bottom: 14px;
          width: 89%;
          @media #{$cr19-medium-up} {
            position: unset;
            width: 100%;
          }
        }
      }
    }
  }
}
